

/* For Mobile View */
.item-welcome-board{
  grid-area: welcome-board;
}

.activity,
.item-feed-activity,
.item-resources-activity,
.item-studygroups-activity{
  padding: 20px;
}
 .item-activity-board{
  grid-area: activity-board;
  display: grid;
  grid-template-columns: auto; 
  grid-template-rows: auto;
  grid-gap: 0px 20px;
  grid-template-areas:
    "feedtitle"
    "feeds"
    "resourcetitle" 
    "resources"
    "studytitle"
    "studygroups"
}
.stats,
.item-feed-stats,
.item-resource-stats{
  padding: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;

}

.item-statistics{
  grid-area: statistics;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas:
    "feedStats resourceStats"
}

.welcome-message-box{
  grid-area: welcomeMessageBox;
  align-self: center;
  padding: 10px;
}


.item-welcome-board{
  grid-area: welcome-board;
  background-color: white;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas:
    "welcomeMessageBox"
    "statistics"
    
}


.dashboard-container {
  display: grid;
  grid-template-columns: 1fr; 
  grid-template-rows: 1fr;
  background-color: white;

  grid-template-areas:
    "welcome-board"
    "activity-board"
  }


/* For any screen larger than 900px */
@media (min-width: 900px){
  /* .typewriter{
    grid-area: typewriter;
    overflow: hidden;
    border-right: .15em solid rgba(24, 144, 255, 1);
    white-space: nowrap; 
    margin: 0 auto; 
    letter-spacing: .20em;
    animation: 
      typing 2s steps(20),
      blink-caret .3s step-end infinite;
  }
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: rgba(24, 144, 255, 1); }
  } */

  .welcome-message-box{
    grid-area: welcomeMessageBox;
    display: grid;
    grid-template-columns: auto 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "typewriter . ."
  }

  .item-feed-stats,
  .item-resource-stats{
    padding: 10px;
    display: flex;
    justify-content: space-around;
    /* align-items: center; */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  }
  .item-feed-stats:hover{
    grid-area: feedStats;
    box-shadow: 0 1px 4px rgba(24, 144, 255, 1);  
    
  }
  .item-resource-stats:hover{
    grid-area: resourceStats;
    box-shadow: 0 1px 4px rgba(24, 144, 255, 1);
  }
  

  .item-statistics{
    grid-area: statistics;
    display: grid;
    /* padding: 25px;
    width: 712px; */
    grid-gap: 0px 20px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "feedStats resourceStats ."
    
  }

  .item-welcome-board{
    grid-area: welcome-board;
    background-color: white;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas:
      "welcomeMessageBox"
      "statistics"
  }

  /* Extends classes so classes can inherit */
  .title, 
  .item-feed-title,
  .item-resource-title,
  .item-studygroup-title{
    text-align: center;
    /* background-color:#1890ff; */
    align-self: center;
    /* box-shadow: 0 1px 4px rgba(0,21,41,.08);  */
  }

  .item-feed-title{
    grid-area: feedtitle;

  }

  .item-resource-title{
    grid-area: resourcetitle;
  }

  .item-studygroup-title{
    grid-area: studytitle;
  }

  /* Extends classes so classes can inherit */
  .activity,
  .item-feed-activity,
  .item-resources-activity,
  .item-studygroups-activity{
    background-color: white;
    padding: 10px;
    overflow: auto;
    /* box-shadow: 0 1px 4px rgba(24, 144, 255, 1); */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .item-feed-activity:hover{
    grid-area: feeds;
    box-shadow: 0 1px 4px rgba(24, 144, 255, 1);
  }

  .item-resources-activity:hover{
    grid-area: resources; 
    box-shadow: 0 1px 4px rgba(24, 144, 255, 1);
  }

  .item-studygroups-activity:hover{
    grid-area: studygroups; 
    box-shadow: 0 1px 4px rgba(24, 144, 255, 1);
  }

  .item-activity-board{
    grid-area: activity-board;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; 
    grid-template-rows: 60px 600px;
    grid-gap: 0px 20px;
    grid-template-areas:

      "feedtitle resourcetitle studytitle"
      "feeds resources studygroups"
  }

  .dashboard-container {
    display: grid;
    padding: 30px;
    grid-template-columns: 1fr; 
    grid-template-rows: 1fr;
    background-color: white;
    grid-gap: 30px 0px;
    grid-template-areas:
      "welcome-board"
      "activity-board"

    }
}
/*
.grid-container {
  display: grid;
  padding-top: 20px;  
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 30px 0px;
  background:white; 
  padding: 20px;
  grid-template-areas:
  "title . ."
  "content . ."
}

.grid-container > div {
  border: 1px solid  Gainsboro;
  background-color: white;
  height: 300px;
  overflow-y:scroll;
}

.grid-bottom {
  grid-gap: 20px 5px;
  padding-top: 70px;
  display: grid;
  height: 100px;
  grid-template:auto;
  gap: 20px 5px;
  
}

.grid-bottom > div {
  border: 1px solid  Gainsboro;
  background-color: white;
  height: 270px;
  line-height: 38px;
}

 */


  
  