
/* Custom Scrollbar NOT SUPPORTED BY FIREFOX */
::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar{
	width: 9px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
}

/* CSS for mobile */

.item-title-header{
  grid-area: titleHeader;
  /* justify-self: center;
  align-items: center; */
  /* width: 100%; */
  
}

.item-header {
    grid-area: header;
    z-index: 1;
    box-shadow: 0 1px 4px rgba(0,21,41,.08);
    /* justify-self: center;
    background:white;  */
}
.item-body {
    grid-area: main;
    /* background:#fff; */
    /* background: blue; */
    /* min-height: 100%; */
    justify-content: center;

}
.item-footer {
    grid-area: footer;
    text-align: center;
}
.container{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 60px auto 80px;
    grid-template-areas:
    "header"
    "main"
    "footer"
}

.item-sider{
    display: none;
}
.pageTitle{
    text-align: center;
    
}

.hamburger-menu{
    grid-area: menu;
    /* padding: 10px; */
    /* z-index: 10; */
    position: sticky;
}

/* For any screen larger than 900px */
@media (min-width: 900px){
    .hamburger-menu{
        grid-area: menu;
        display: none;
    }
    
    .logo-area {
        height:64px;
        padding-left:24px;
        background-color:#002140;
        line-height: 64px;
        position: relative;
        overflow: hidden; /* hides logo-text*/
    
    }
    .logo-text{
        grid-area: logo-text;
        display: inline-block;
        font-weight: 600;
        font-size: 20px;
        margin: 0 0 0 12px;
        font-family: Avenir,Helvetica Neue,Arial,Helvetica,sans-serif;
        vertical-align: middle;
        color:white;
        overflow: hidden;
    }
    .logo{
        grid-area: logo;
        vertical-align: middle;
        height:32px;
    }
   

    .stickyNav{
        top: 40px;
        position: sticky; 
    }

    .item-header-essentials{ 
        grid-area: essentials;
        /* justify-self: right; */
        display:flex;
        flex-flow: row;
        justify-content: flex-end;
    }

    .item-header {
        grid-area: header;
        font-size:20px;
        position: unset;
        box-shadow: 0 1px 4px rgba(0,21,41,.08);
        background:white;
        display:inline-block;
        text-align: center;
        
        /* height:100%; */
        /* justify-self:  center;  */
        /* /* align-items: center; */
        
    }
   
    .item-body {
        grid-area: main;
        /* background:#fff; */
        /* background: blue; */
        /* min-height: 100%; */
        justify-content: center;
        

    }
    .item-sider{
        grid-area: sider;
        height: 100vh;
        display: block;
        min-height: 100%;
        box-shadow: 2px 0 6px rgba(0,21,41,.35);
        /* position: sticky; */
        /* left: 0; */

    }
    .item-footer {
        grid-area: footer;
        text-align: center;
        /* height: auto; */
        /* margin-top: -50px; */


    }

    .container{
        display: grid;
        grid-template-columns: [first]auto [line2]50px 1fr [line4]50px;
        grid-template-rows: 64px [row1-end]50px auto 80px;
        /* grid-gap: 50px 0px; */
        background:#f0f2f5;
        /* background:red; */
        grid-template-areas:
        "sider header header header"
        "sider . . ."
        "sider . main ."
        "sider . footer ."
    }

}
