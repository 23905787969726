

/* For Mobile View */

/* resource card container */
.item-resource-cards{
  grid-area: cards;
  justify-content: center;
 
} 
.item-resource-info-mobile{
  grid-area: resourceInfo;
  display: block;
  /* padding: 50px; */
}

.item-resource-info{
  grid-area: resourceInfo;
  display: none;
  /* padding: 50px; */
}

/* for each resource card */
.resource-card{
  /* height: 170px; */
  width: 80vw;
}

.item-resource-createdby{
  grid-area:resourceCreatedBy;
  padding: 20px;
}

.item-resources{
  grid-area: resources;  
  padding: 10px;
  display: grid;
  grid-template-columns: auto ;
  grid-template-rows: auto;
  grid-template-areas: 
    "cards"
    "resourceInfo"
}

.resources-container{
  display: grid; 
  background:white;
  padding: 20px;
  grid-template-columns: auto;
  grid-template-rows: auto ;
  grid-template-areas:
  ". "
  "resources"
}

.res-mobile-view{
  display: block;
}
/* For any screen larger than 900px */
@media (min-width: 900px){
    .res-mobile-view{
      display: none;
    }
    .notecard{
      width: auto;
      height: 200px;
      padding: 20px;
      overflow-x: hidden;
      overflow-y: auto;
      display:grid;
      justify-content: center;
      align-items: center;
    }

    .resource-card{
      height: 170px;
      width: 300px;
     
    }

    .item-resource-createdby{
      grid-area:resourceCreatedBy;
      padding: 20px;
    }
    
    .item-resource-cards{
      grid-area: cards; 
      padding: 20px;
    } 
    
    .item-resource-info{
      grid-area: resourceInfo;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 0px 8px 0 rgba(0, 0, 0, 0.19);
      grid-template-areas: 
        "resourceCreatedBy"
        "cards"
    }

    .item-resources{
      grid-area: resources;  
      display: grid;
      padding: 10px;
      grid-gap: 0px 30px;
      grid-template-columns: 300px auto ;
      grid-template-rows: auto ;
      grid-template-areas: 
        "cards resourceInfo"
    }

   
    .resources-container{
        display: grid; 
        background:white;
        padding: 20px;
        grid-template-columns: auto;
        grid-template-rows: auto ;
        grid-template-areas:
        ". "
        "resources"
    }
}