
/* For Mobile view */


.item-profile{ 
    background: white; 
    display: grid;
    grid-area: profile;
    grid-template-columns: auto;
    grid-template-rows: auto;
    background: white;
    box-shadow: 0 1px 4px rgba(0,21,41,.08);
    grid-template-areas: 
    "profileUser"
    "profileSettings"
   
}
.item-profile-user{
    grid-area: profileUser;
    justify-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
} 

.item-profile-settings{
    grid-area: profileSettings;
    padding:20px;
}
.profile-body {
    grid-area: body;
    display:grid;
    background: white;
    padding:20px;
}

.profile-container{
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-gap: 24px 0px;
    padding: 20px;
    align-content: center;
    grid-template-areas:
    "profile"
    "body"
}


/* For any screen larger than 1000px */
@media (min-width: 900px){
    .item-profile-settings{
        grid-area: profileSettings;
        padding:20px;
    }
    .item-profile-user{
        grid-area: profileUser;
        justify-self: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 50px;
    }

    .item-profile{
        display: grid;
        background: white;
        box-shadow: 0 1px 4px rgba(0,21,41,.08);
        grid-area: profile;
        grid-template-columns: auto;
        grid-template-rows:auto;
        grid-template-areas: 
        "profileUser"
        "profileSettings"
        /* background: white; */
        /* text-align: center; */
    }
    .gray-col{
        grid-area: gray-col;
        background: #f0f2f5;
        /* background: pink; */
    }

    .profile-body {
        grid-area: body;
        /* display:grid; */
        background: white;
        padding: 20px;
       
        box-shadow: 0 1px 4px rgba(0,21,41,.08);
        /* grid-column: 3;
        grid-row: 1/3; */

    }

    .profile-container{
        display: grid;
        grid-template-columns: auto 30px 1fr;
        grid-template-rows: auto;
        /* grid-gap: 0px; */
        /* background:white; */
        grid-template-areas:
        "profile gray-col body"
        " . gray-col body"
    }
}