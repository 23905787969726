body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}




/* Sign in  */
/* CSS for smaller screens */
.item-image {
    grid-area: image;
    display: none;

}

.item-sign-in {
    grid-area: signIn;
    /* justify-self: center; */

}
.item-col-line {
    grid-area: columnLine;
    height: 400px;
    justify-self: center;
    display: none;

}

.register-container{
    display: grid;
    grid-template-columns: 1fr;
    padding: 60px;
    grid-template-areas:
    "signIn"

}

/* CSS for larger screens */
@media (min-width: 900px){
    .register-container{
        display: grid;
        padding: 0px;
        grid-template-columns: 1fr 200px 1fr;
        grid-template-rows: 100px 1fr;
        grid-template-areas:
        ". . ."
        "image columnLine signIn"
    }
    .item-sign-in {
        grid-area: signIn;
        /* justify-self: start; */
    }
    .item-image{
        display:block;
        grid-area: image;
        height: 400px;
        width: 400px;
        justify-self: end;
    }
    .item-col-line {
        display:block;
        grid-area: columnLine;
        height: 400px;
        justify-self: center;
    }

}

/* SignUp */
.login-form {
    max-width: 300px;
}

.login-form-forgot {
    float: right;
}

.login-form-button {
    width: 100%;
}


 /* For mobile view */
 .card-container{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto ;
    /* grid-gap: 30px 0px; */
    /* background:white; */
    padding: 20px;
    grid-template-areas:
    "feed"
}
.item-subfeed{
    display:none;
}

.mobile-view{
    display: block;
}
.window-view{
    display: none;
}
/* For any screen larger than 900px */
@media (min-width: 900px){
    .mobile-view{
        display: none;
    }
    .window-view{
        display: block;
    }
    .item-feed{
        grid-area: feed;
        background:white;
        padding: 20px;
        box-shadow: 0 1px 4px rgba(0,21,41,.08);

    }
    .item-subpost{
        grid-area: subpost;
        background: white;
        padding: 10px;
    }
    .item-subfeed-info{
        grid-area: sub-info;
        background:white;
        padding: 10px;
    }
    .item-divider{
        grid-area: divider;
        /* justify-self: center; */
        background:white;
        align-self: start;
    }
    .item-subfeed{
        display:grid;
        grid-template-columns: auto;
        grid-template-rows: 150px 20px auto;
        grid-area: subfeed;
        background:white;
        box-shadow: 0 1px 4px rgba(0,21,41,.08); 
        grid-template-areas:
        "subpost"
        "divider"
        "sub-info"
    }
    .item-gap{
        grid-area: gap;
    }

    .card-container{
        display: grid;
        grid-template-columns: 1fr 30px 300px;
        grid-template-rows: auto ;
        /* grid-gap: 30px 0px; */
        /* background:white; */
        /* padding: 20px; */
        grid-template-areas:
        "feed gap subfeed"
        "feed gap ."
    }
}


/* For Mobile View */
.item-welcome-board{
  grid-area: welcome-board;
}

.activity,
.item-feed-activity,
.item-resources-activity,
.item-studygroups-activity{
  padding: 20px;
}
 .item-activity-board{
  grid-area: activity-board;
  display: grid;
  grid-template-columns: auto; 
  grid-template-rows: auto;
  grid-gap: 0px 20px;
  grid-template-areas:
    "feedtitle"
    "feeds"
    "resourcetitle" 
    "resources"
    "studytitle"
    "studygroups"
}
.stats,
.item-feed-stats,
.item-resource-stats{
  padding: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;

}

.item-statistics{
  grid-area: statistics;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas:
    "feedStats resourceStats"
}

.welcome-message-box{
  grid-area: welcomeMessageBox;
  align-self: center;
  padding: 10px;
}


.item-welcome-board{
  grid-area: welcome-board;
  background-color: white;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas:
    "welcomeMessageBox"
    "statistics"
    
}


.dashboard-container {
  display: grid;
  grid-template-columns: 1fr; 
  grid-template-rows: 1fr;
  background-color: white;

  grid-template-areas:
    "welcome-board"
    "activity-board"
  }


/* For any screen larger than 900px */
@media (min-width: 900px){
  /* .typewriter{
    grid-area: typewriter;
    overflow: hidden;
    border-right: .15em solid rgba(24, 144, 255, 1);
    white-space: nowrap; 
    margin: 0 auto; 
    letter-spacing: .20em;
    animation: 
      typing 2s steps(20),
      blink-caret .3s step-end infinite;
  }
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: rgba(24, 144, 255, 1); }
  } */

  .welcome-message-box{
    grid-area: welcomeMessageBox;
    display: grid;
    grid-template-columns: auto 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "typewriter . ."
  }

  .item-feed-stats,
  .item-resource-stats{
    padding: 10px;
    display: flex;
    justify-content: space-around;
    /* align-items: center; */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  }
  .item-feed-stats:hover{
    grid-area: feedStats;
    box-shadow: 0 1px 4px rgba(24, 144, 255, 1);  
    
  }
  .item-resource-stats:hover{
    grid-area: resourceStats;
    box-shadow: 0 1px 4px rgba(24, 144, 255, 1);
  }
  

  .item-statistics{
    grid-area: statistics;
    display: grid;
    /* padding: 25px;
    width: 712px; */
    grid-gap: 0px 20px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "feedStats resourceStats ."
    
  }

  .item-welcome-board{
    grid-area: welcome-board;
    background-color: white;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas:
      "welcomeMessageBox"
      "statistics"
  }

  /* Extends classes so classes can inherit */
  .title, 
  .item-feed-title,
  .item-resource-title,
  .item-studygroup-title{
    text-align: center;
    /* background-color:#1890ff; */
    align-self: center;
    /* box-shadow: 0 1px 4px rgba(0,21,41,.08);  */
  }

  .item-feed-title{
    grid-area: feedtitle;

  }

  .item-resource-title{
    grid-area: resourcetitle;
  }

  .item-studygroup-title{
    grid-area: studytitle;
  }

  /* Extends classes so classes can inherit */
  .activity,
  .item-feed-activity,
  .item-resources-activity,
  .item-studygroups-activity{
    background-color: white;
    padding: 10px;
    overflow: auto;
    /* box-shadow: 0 1px 4px rgba(24, 144, 255, 1); */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .item-feed-activity:hover{
    grid-area: feeds;
    box-shadow: 0 1px 4px rgba(24, 144, 255, 1);
  }

  .item-resources-activity:hover{
    grid-area: resources; 
    box-shadow: 0 1px 4px rgba(24, 144, 255, 1);
  }

  .item-studygroups-activity:hover{
    grid-area: studygroups; 
    box-shadow: 0 1px 4px rgba(24, 144, 255, 1);
  }

  .item-activity-board{
    grid-area: activity-board;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; 
    grid-template-rows: 60px 600px;
    grid-gap: 0px 20px;
    grid-template-areas:

      "feedtitle resourcetitle studytitle"
      "feeds resources studygroups"
  }

  .dashboard-container {
    display: grid;
    padding: 30px;
    grid-template-columns: 1fr; 
    grid-template-rows: 1fr;
    background-color: white;
    grid-gap: 30px 0px;
    grid-template-areas:
      "welcome-board"
      "activity-board"

    }
}
/*
.grid-container {
  display: grid;
  padding-top: 20px;  
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 30px 0px;
  background:white; 
  padding: 20px;
  grid-template-areas:
  "title . ."
  "content . ."
}

.grid-container > div {
  border: 1px solid  Gainsboro;
  background-color: white;
  height: 300px;
  overflow-y:scroll;
}

.grid-bottom {
  grid-gap: 20px 5px;
  padding-top: 70px;
  display: grid;
  height: 100px;
  grid-template:auto;
  gap: 20px 5px;
  
}

.grid-bottom > div {
  border: 1px solid  Gainsboro;
  background-color: white;
  height: 270px;
  line-height: 38px;
}

 */


  
  


/* For Mobile View */

/* resource card container */
.item-resource-cards{
  grid-area: cards;
  justify-content: center;
 
} 
.item-resource-info-mobile{
  grid-area: resourceInfo;
  display: block;
  /* padding: 50px; */
}

.item-resource-info{
  grid-area: resourceInfo;
  display: none;
  /* padding: 50px; */
}

/* for each resource card */
.resource-card{
  /* height: 170px; */
  width: 80vw;
}

.item-resource-createdby{
  grid-area:resourceCreatedBy;
  padding: 20px;
}

.item-resources{
  grid-area: resources;  
  padding: 10px;
  display: grid;
  grid-template-columns: auto ;
  grid-template-rows: auto;
  grid-template-areas: 
    "cards"
    "resourceInfo"
}

.resources-container{
  display: grid; 
  background:white;
  padding: 20px;
  grid-template-columns: auto;
  grid-template-rows: auto ;
  grid-template-areas:
  ". "
  "resources"
}

.res-mobile-view{
  display: block;
}
/* For any screen larger than 900px */
@media (min-width: 900px){
    .res-mobile-view{
      display: none;
    }
    .notecard{
      width: auto;
      height: 200px;
      padding: 20px;
      overflow-x: hidden;
      overflow-y: auto;
      display:grid;
      justify-content: center;
      align-items: center;
    }

    .resource-card{
      height: 170px;
      width: 300px;
     
    }

    .item-resource-createdby{
      grid-area:resourceCreatedBy;
      padding: 20px;
    }
    
    .item-resource-cards{
      grid-area: cards; 
      padding: 20px;
    } 
    
    .item-resource-info{
      grid-area: resourceInfo;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 0px 8px 0 rgba(0, 0, 0, 0.19);
      grid-template-areas: 
        "resourceCreatedBy"
        "cards"
    }

    .item-resources{
      grid-area: resources;  
      display: grid;
      padding: 10px;
      grid-gap: 0px 30px;
      grid-template-columns: 300px auto ;
      grid-template-rows: auto ;
      grid-template-areas: 
        "cards resourceInfo"
    }

   
    .resources-container{
        display: grid; 
        background:white;
        padding: 20px;
        grid-template-columns: auto;
        grid-template-rows: auto ;
        grid-template-areas:
        ". "
        "resources"
    }
}
.item-studygroup{
    grid-area: studygroup;
}

.item-studygroup-content{
    grid-area: studygroup-content;
}

.item-divider{
    grid-area: studygroup-divider;
}

.studygroup-container{
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto ;
    background:white;
    box-shadow: 0 1px 4px rgba(0,21,41,.08); 
    padding: 20px;
    grid-template-areas:
    "studygroup"
    "studygroup-content"
}


/* For any screen larger than 900px */
@media (min-width: 900px){
    .dynamic-delete-button {
        cursor: pointer;
        position: relative;
        top: 4px;
        font-size: 20px;
        color: #999;
        -webkit-transition: all .3s;
        transition: all .3s;
    }

    .studygroup-modal{
        display: flex;
        flex-direction: column;
        /* align-items: center; */
    }


    .item-studygroup{
        grid-area: studygroup;
        
    }

    .item-groupCard{
        grid-area: groupcard;
        padding: 20px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 0px 8px 0 rgba(0, 0, 0, 0.19);
    }
    .item-messageBoard{
        grid-area: messageboard;
        padding: 20px;
        /* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 0px 8px 0 rgba(0, 0, 0, 0.19); */
    }

    .item-studygroup-content{
        grid-area: studygroup-content;
        display:grid;
        grid-gap: 20px;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto ;
        grid-template-areas: 
            "groupcard"
            "messageboard"
    }

    .item-divider{
        grid-area: studygroup-divider;
    }

    .studygroup-container{
        display: grid;
        grid-template-columns: 300px 50px auto;
        grid-template-rows: auto ;
        background:white;
        box-shadow: 0 1px 4px rgba(0,21,41,.08); 
        padding: 20px;
        grid-template-areas:
        "studygroup studygroup-divider studygroup-content"
    }
}

/* For Mobile view */


.item-profile{ 
    background: white; 
    display: grid;
    grid-area: profile;
    grid-template-columns: auto;
    grid-template-rows: auto;
    background: white;
    box-shadow: 0 1px 4px rgba(0,21,41,.08);
    grid-template-areas: 
    "profileUser"
    "profileSettings"
   
}
.item-profile-user{
    grid-area: profileUser;
    justify-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
} 

.item-profile-settings{
    grid-area: profileSettings;
    padding:20px;
}
.profile-body {
    grid-area: body;
    display:grid;
    background: white;
    padding:20px;
}

.profile-container{
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-gap: 24px 0px;
    padding: 20px;
    align-content: center;
    grid-template-areas:
    "profile"
    "body"
}


/* For any screen larger than 1000px */
@media (min-width: 900px){
    .item-profile-settings{
        grid-area: profileSettings;
        padding:20px;
    }
    .item-profile-user{
        grid-area: profileUser;
        justify-self: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 50px;
    }

    .item-profile{
        display: grid;
        background: white;
        box-shadow: 0 1px 4px rgba(0,21,41,.08);
        grid-area: profile;
        grid-template-columns: auto;
        grid-template-rows:auto;
        grid-template-areas: 
        "profileUser"
        "profileSettings"
        /* background: white; */
        /* text-align: center; */
    }
    .gray-col{
        grid-area: gray-col;
        background: #f0f2f5;
        /* background: pink; */
    }

    .profile-body {
        grid-area: body;
        /* display:grid; */
        background: white;
        padding: 20px;
       
        box-shadow: 0 1px 4px rgba(0,21,41,.08);
        /* grid-column: 3;
        grid-row: 1/3; */

    }

    .profile-container{
        display: grid;
        grid-template-columns: auto 30px 1fr;
        grid-template-rows: auto;
        /* grid-gap: 0px; */
        /* background:white; */
        grid-template-areas:
        "profile gray-col body"
        " . gray-col body"
    }
}
.ant-carousel .slick-slide {
    text-align: center;
    height: 160px;
    line-height: 160px;
    background: #364d79;
    overflow: hidden;
}

.ant-carousel .slick-slide h3 {
    color: #fff;
}

/* Custom Scrollbar NOT SUPPORTED BY FIREFOX */
::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar{
	width: 9px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
}

/* CSS for mobile */

.item-title-header{
  grid-area: titleHeader;
  /* justify-self: center;
  align-items: center; */
  /* width: 100%; */
  
}

.item-header {
    grid-area: header;
    z-index: 1;
    box-shadow: 0 1px 4px rgba(0,21,41,.08);
    /* justify-self: center;
    background:white;  */
}
.item-body {
    grid-area: main;
    /* background:#fff; */
    /* background: blue; */
    /* min-height: 100%; */
    justify-content: center;

}
.item-footer {
    grid-area: footer;
    text-align: center;
}
.container{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 60px auto 80px;
    grid-template-areas:
    "header"
    "main"
    "footer"
}

.item-sider{
    display: none;
}
.pageTitle{
    text-align: center;
    
}

.hamburger-menu{
    grid-area: menu;
    /* padding: 10px; */
    /* z-index: 10; */
    position: -webkit-sticky;
    position: sticky;
}

/* For any screen larger than 900px */
@media (min-width: 900px){
    .hamburger-menu{
        grid-area: menu;
        display: none;
    }
    
    .logo-area {
        height:64px;
        padding-left:24px;
        background-color:#002140;
        line-height: 64px;
        position: relative;
        overflow: hidden; /* hides logo-text*/
    
    }
    .logo-text{
        grid-area: logo-text;
        display: inline-block;
        font-weight: 600;
        font-size: 20px;
        margin: 0 0 0 12px;
        font-family: Avenir,Helvetica Neue,Arial,Helvetica,sans-serif;
        vertical-align: middle;
        color:white;
        overflow: hidden;
    }
    .logo{
        grid-area: logo;
        vertical-align: middle;
        height:32px;
    }
   

    .stickyNav{
        top: 40px;
        position: -webkit-sticky;
        position: sticky; 
    }

    .item-header-essentials{ 
        grid-area: essentials;
        /* justify-self: right; */
        display:flex;
        flex-flow: row;
        justify-content: flex-end;
    }

    .item-header {
        grid-area: header;
        font-size:20px;
        position: unset;
        box-shadow: 0 1px 4px rgba(0,21,41,.08);
        background:white;
        display:inline-block;
        text-align: center;
        
        /* height:100%; */
        /* justify-self:  center;  */
        /* /* align-items: center; */
        
    }
   
    .item-body {
        grid-area: main;
        /* background:#fff; */
        /* background: blue; */
        /* min-height: 100%; */
        justify-content: center;
        

    }
    .item-sider{
        grid-area: sider;
        height: 100vh;
        display: block;
        min-height: 100%;
        box-shadow: 2px 0 6px rgba(0,21,41,.35);
        /* position: sticky; */
        /* left: 0; */

    }
    .item-footer {
        grid-area: footer;
        text-align: center;
        /* height: auto; */
        /* margin-top: -50px; */


    }

    .container{
        display: grid;
        grid-template-columns: [first]auto [line2]50px 1fr [line4]50px;
        grid-template-rows: 64px [row1-end]50px auto 80px;
        /* grid-gap: 50px 0px; */
        background:#f0f2f5;
        /* background:red; */
        grid-template-areas:
        "sider header header header"
        "sider . . ."
        "sider . main ."
        "sider . footer ."
    }

}

